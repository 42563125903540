var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"header":_vm.$t('automatic.ip_pool.name')}},[_c('complex-table',{attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"search-config":_vm.searchConfig,"selects":_vm.selects},on:{"search":_vm.search,"update:selects":function($event){_vm.selects=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{attrs:{"size":"small","disabled":_vm.selects.length===0},on:{"click":function($event){return _vm.del()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('commons.table.name'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"subnet","label":_vm.$t('automatic.ip_pool.subnet'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.subnet)+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"ipUsed","label":_vm.$t('automatic.ip_pool.ip_usage'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":"info"},on:{"click":function($event){return _vm.openIpList(row)}}},[_vm._v(_vm._s(row.ipUsed)+" / "+_vm._s(row.ips.length))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"createdAt","label":_vm.$t('commons.table.create_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetimeFormat")(row.createdAt)))]}}])}),_c('fu-table-operations',{attrs:{"buttons":_vm.buttons,"label":_vm.$t('commons.table.action')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }